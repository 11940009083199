<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn @click="openDialog('create')" color="primary">
          <v-icon left>mdi-plus</v-icon> Agregar Subgrupo
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table :headers="headers" :items="subgroups" item-key="name">
          <template v-slot:[`item.members`]="{ item }">
            <span class="mr-2 ml-3">{{ item.members.length }}</span>
            <v-btn icon small @click="openMembersDialog(item.members)">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small @click="openDialog('edit', item)" class="ml-1 mr-5"
              >mdi-pencil</v-icon
            >
            <v-icon small @click="openDeleteDialog(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="deleteDialog" max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Confirmar eliminación</span>
        </v-card-title>
        <v-card-text>
          ¿Estás seguro de que quieres eliminar el subgrupo "{{
            subgroupToDelete ? subgroupToDelete.name : ""
          }}"?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" @click="confirmDeleteSubgroup">Eliminar</v-btn>
          <v-btn @click="closeDeleteDialog">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            dialogType === "create" ? "Crear Subgrupo" : "Editar Subgrupo"
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="formValid">
            <v-text-field
              v-model="editedSubgroup.name"
              :rules="[rules.required]"
              label="Nombre del Subgrupo"
            ></v-text-field>
            <br />

            <v-autocomplete
              v-model="selectedUsers"
              label="Agregar miembros."
              multiple
              chips
              clearable
              deletable-chips
              :loading="loadingUsers"
              :items="users"
              item-text="name"
              item-value="_id"
              :search-input.sync="searchName"
              @keyup.enter="handleEnterKey"

              hide-no-data
              hide-selected
            ></v-autocomplete>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog">Cancelar</v-btn>
          <v-btn :disabled="!isFormValid" @click="saveSubgroup">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="membersDialog" max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Miembros del Subgrupo</span>
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item v-for="member in selectedMembers" :key="member._id">
              <v-list-item-content>
                {{ member.name }} {{ member.firstLastname }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeMembersDialog">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      deleteDialog: false,
      subgroupToDelete: null,
      searchName: "",
      id: "",
      headers: [
        { text: "Nombre", value: "name" },
        { text: "Miembros", value: "members" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      testo: [],
      subgroups: [],
      users: [],
      tinton: [],
      selectedUsers: [],
      selectedMembers: [],
      dialog: false,
      membersDialog: false,
      dialogType: "",
      editedSubgroup: {},
      formValid: false,
      rules: {
        required: (value) => !!value || "Este campo es requerido",
      },
      loadingUsers: false,
      eventsSearchData: {
        name: "",
        page: 1,
        perPage: 10,
        validationStatus: "",
        role: "",
      },
      typingTimer: null,
      preservedSearchName: "",
    };
  },
  created() {
    this.getAllUsers2();
    //this.realoadUserData({ id: this.id });
    this.fetchSubgroups();
    //this.fetchUsers();
  },
  watch: {
    /*searchName(newVal) {
      console.log("Buscando:", newVal);
      this.fetchUsers(newVal);
    },*/
  },
  computed: {
  // Computed property para verificar si el formulario es válido
  isFormValid() {
    return this.editedSubgroup.name && this.selectedUsers.length > 0;
  },
},

  methods: {
    ...mapMutations("notification", ["show"]),
    ...mapActions("user", ["realoadUserData"]),
    formatMembers(members) {
      return members.map((member) => member.name).join(", ");
    },
    handleEnterKey() {
      this.$nextTick(() => {
        if (this.$refs.autocompleteMenu) {
          this.preservedSearchName = this.searchName;

          this.$refs.autocompleteMenu.isMenuActive = false;

          setTimeout(() => {
            this.$refs.autocompleteMenu.isMenuActive = true;
            this.searchName = this.preservedSearchName;
          }, 100);
        }
      });
    },

    async updateUserSubgroups(subgroupName, userIds) {
      try {
        for (const userId of userIds) {
          const putUser = {
            subgroup: subgroupName,
          };

          const response = await this.axios({
            method: "put",
            url: "/members/form/" + userId,
            data: putUser,
          });

          if (response.status !== 200) {
            console.error(`Error actualizando el subgrupo`);
          }
        }
        this.show({
          text: "Subgrupo actualizado exitosamente!",
          color: "success",
          time: 2000,
        });
      } catch (error) {
        console.error("Error actualizando subgrupo:", error);
        this.show({
          text: "¡Error al actualizar subgrupo!",
          color: "error",
        });
      }
    },

    async fetchSubgroups() {
      try {
        const response = await axios.get("/subgroups");
        this.subgroups = response.data;
      } catch (error) {
        console.error("Error fetching subgroups:", error);
      }
    },

    async getAllUsers2() {
      try {
        const response = await axios.get("/users/allUsersSubgroups");
        this.users = response.data.map((member) => ({
            _id: member._id,
            name: `${member.name} ${member.firstLastname}`,
          }));



        console.log("TEST",this.users)
      } catch (error) {
        console.error("Error creating subgroup:", error);
      }
    },

    async fetchUsers(search) {
      this.loadingUsers = true;
      this.eventsSearchData.name = search || this.searchName;
      this.eventsSearchData.branch = this.id;

      try {
        const response = await this.getBranchMembersPaginated(
          this.eventsSearchData
        );

        if (response && response.data && Array.isArray(response.data.members)) {
          this.users = response.data.members.map((member) => ({
            _id: member._id,
            name: `${member.name} ${member.firstLastname}`,
          }));

          // Imprimir los usuarios en la consola
          console.log("Usuarios obtenidos:", this.users);

          this.$nextTick(() => {
            if (this.$refs.autocompleteMenu) {
              this.$refs.autocompleteMenu.isMenuActive = true; // Reactiva el menú
            }
          });
        } else {
          console.error("Invalid response structure:", response);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        this.loadingUsers = false;
      }
    },
    openDialog(type, subgroup = {}) {
      this.dialogType = type;
      this.editedSubgroup = { ...subgroup };
      this.selectedUsers = subgroup.members || [];
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.$refs.form.reset();
    },
    openMembersDialog(members) {
      this.selectedMembers = members;
      this.membersDialog = true;
    },
    closeMembersDialog() {
      this.membersDialog = false;
      this.selectedMembers = [];
    },
    async saveSubgroup() {
      if (this.dialogType === "create") {
        await this.createSubgroup();
      } else {
        await this.updateSubgroup();
      }
      this.fetchSubgroups();

      //this.realoadUserData({ id: this.id });
      this.searchName = "";
      //this.fetchUsers();

      this.closeDialog();
    },

 

    async createSubgroup() {
      try {
        await axios.post("/subgroups", {
          name: this.editedSubgroup.name,
          members: this.selectedUsers,
        });
        this.show({
          text: "Subgrupo creado exitosamente!",
          color: "success",
          time: 2000,
        });
      } catch (error) {
        console.error("Error creating subgroup:", error);
        this.show({
          text: "Ocurrió un error al crear el subgrupo.",
          color: "error",
        });
      }
    },
    async updateSubgroup() {
      try {
        await axios.put(`/subgroups/${this.editedSubgroup._id}`, {
          name: this.editedSubgroup.name,
          members: this.selectedUsers,
        });
        this.show({
          text: "Subgrupo actualizado exitosamente!",
          color: "success",
          time: 2000,
        });
      } catch (error) {
        console.error("Error updating subgroup:", error);
        this.show({
          text: "Ocurrió un error al actualizar el subgrupo.",
          color: "error",
        });
      }
    },

    async deleteSubgroup(subgroup) {
      try {
        await axios.delete(`/subgroups/${subgroup._id}`);
        this.fetchSubgroups();
      } catch (error) {
        console.error("Error deleting subgroup:", error);
      }
    },

    openDeleteDialog(subgroup) {
      this.subgroupToDelete = subgroup;
      this.deleteDialog = true;
    },

    // Cierra el diálogo de eliminación
    closeDeleteDialog() {
      this.deleteDialog = false;
      this.subgroupToDelete = null;
    },

    // Confirma la eliminación del subgrupo
    async confirmDeleteSubgroup() {
      if (this.subgroupToDelete) {
        try {
          await axios.delete(`/subgroups/${this.subgroupToDelete._id}`);
          this.fetchSubgroups(); // Recarga los subgrupos
          this.show({
            text: "Subgrupo eliminado exitosamente!",
            color: "success",
            time: 2000,
          });
        } catch (error) {
          console.error("Error deleting subgroup:", error);
          this.show({
            text: "Ocurrió un error al eliminar el subgrupo.",
            color: "error",
          });
        }
      }
      this.closeDeleteDialog();
    },

    ...mapActions("members", ["getBranchMembersPaginated"]),
  },
  watch: {},
};
</script>
